import { createApp, watch } from "vue";
import App from "./App.vue";
import i18n from "./plugins/i18n";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "uikit/dist/js/uikit";
import "./assets/scss/_uikit.scss";
import "./assets/tailwind.css";
import { clickOutSide as vClickOutSide } from "@mahdikhashan/vue3-click-outside";

import moment from "moment";
import "moment/locale/sv";
import "moment/locale/nb";
import "moment/locale/da";
import { durationToString } from "./plugins/helpers";

const localeMap = {
  "en-SE": "en",
  "sv-SE": "sv",
  "nb-NO": "nb",
  "da-DK": "da",
};

const currentLocale = i18n.global.locale;
const momentLocale = localeMap[currentLocale] || "en";
moment.locale(momentLocale);

watch(
  () => i18n.global.locale,
  (newLocale) => {
    const newMomentLocale = localeMap[newLocale] || "en";
    moment.locale(newMomentLocale);
  }
);

const app = createApp(App);

app.config.globalProperties.$filters = {
  time(dateTime) {
    return moment(dateTime).format("HH:mm");
  },
  date(dateTime, format = "D MMM") {
    return moment(dateTime).format(format);
  },
  dateTimeString(dateTime) {
    return moment(dateTime).format("LL");
  },
  duration(ISO8601) {
    const duration = moment.duration(ISO8601);
    return durationToString(duration);
  },
  characterLimit(text, characterLength) {
    return (
      text.slice(0, characterLength) +
      (text.length > characterLength ? "..." : "")
    );
  },
  upperCase(value) {
    return value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  },
};

app.directive("click-outside", vClickOutSide);

app.use(i18n).use(store).use(router).mount("#app");

export default app;
