<template>
  <div>
    <maja-bottom-bar-widget
      :key="locale"
      :active-locale="locale"
      :env="env"
      class="maja-payment z-50"
      :active-tab="activeTab">
    </maja-bottom-bar-widget>
  </div>
</template>

<script>
export default {
  name: "BottomBar",
  data() {
    return {
      locale: "SV",
    };
  },
  computed: {
    activeTab() {
      if (this.$route.path.includes("order")) {
        return "orders";
      }
      if (
        this.$route.path.includes("account") ||
        this.$route.path.includes("passenger") ||
        this.$route.name === "Login"
      ) {
        return "account";
      }
      return null;
    },
    env() {
      return process.env.VUE_APP_ENVIRONMENT;
    },
  },
  watch: {
    "$i18n.locale": {
      handler: function (newVal) {
        const localeMap = {
          "sv-SE": "SV",
          "en-SE": "EN",
          "nb-NO": "NO",
        };
        this.locale = localeMap[newVal] || newVal;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.maja-payment {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
